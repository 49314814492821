import { motion } from "framer-motion"; 
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../App";
import PollServiceInstance from "../../services/pollService";
import Alert from "../components/alerts/Alert";
import Button from "../components/buttons/Button";
import Chip from "../components/cards/chip";
import DisplayCard from "../components/cards/displayCard";
import SignupFormModal from "../components/modals/SignupFormModal";
import { useNavigate } from "react-router";
import UniversalPopup from "../components/modals/UniversalPopup";
import UserDetails from "../components/custom/UserDetails";
import AuthServiceInstance from "../../services/authService"; 
const server_url = process.env.REACT_APP_CDN_URL;
  
const container = {
  hidden: {
    opacity: 0,
    top: 0,
    transition: {
      staggerChildren: 1,
      delayChildren: 1,
      // ease: "easeOut",
      duration: 0.5,
    },
  },
  show: {
    transition: {
      staggerChildren: 0.2,
      delayChildren: 0.3,
      // ease: "easeOut",
      duration: 0.5,
    },
    opacity: 1,
    top: 59,
  },
};



const item = {
  hidden: { opacity: 0, y: 0 },
  show: { opacity: 1, y: 1 },
};

const CreatePollPage = () => {
  const {
    state: { isLoggedIn },
  } = useContext(UserContext);
  const defaultPollData = {
    poll: "",
    options: [{ option: "" }, { option: "" }],
    duration: "0",
  };
  
  const [userInfo, setUserInfo] = useState(null);
  const [pollData, setPollData] = useState(defaultPollData);
  const [pollError, setPollError] = useState({});
  const [errorAlert, setErrorAlert] = useState(false);
  const [pollDurations, setPollDurations] = useState(null);
  const [openSignUpForm, setOpenSignUpForm] = useState(false); 
  const [pollDataForImage, setPollDataForImage] = useState({}); 

  const navigate = useNavigate();

  const [pollAlert, setPollAlert] = useState({
    state: false,
    severity: "",
    message: "",
  });
   
  const [showUserDetailsPopup, setShowUserDetailsPopup] = useState(false);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    PollServiceInstance.getPollDuration()
      .then((response) => {
        setPollDurations(response);
      })
      .catch();
  }, []);

  const handleClearDraft = () => {
    setPollAlert({ state: false });
    setPollData({ ...defaultPollData });
    localStorage.removeItem("draft_poll_data");
  };

  const fetchMe = async () => {
    const user = await AuthServiceInstance.me();
    if (user) {
      setUserInfo(user.data); 
    }
  };

  useEffect(() => {
    fetchMe();
  }, [])

  useEffect(() => {
    const draftPollData = localStorage.getItem("draft_poll_data");
    if (draftPollData?.length) {
      setPollData(JSON.parse(draftPollData));
      setPollAlert({
        state: true,
        message: (
          <div className="flex items-center justify-between w-full">
            <span className="w-full">
              You have unsaved poll. Do you want to continue with the same ?
            </span>
            <div className="flex items-center space-x-6 ">
              <button
                className=""
                onClick={() => setPollAlert({ state: false })}
              >
                <i className="las la-check la-lg text-[#4BB543]" />
              </button>
              <button onClick={handleClearDraft}>
                <i className="las la-times la-lg text-[#EF9A5F]" />
              </button>
            </div>
          </div>
        ),
        severity: "info",
      });
    }
  }, []);

  const handleOptionChange = (optionsIndex,event) => {
    const value = event.target.value;
    const textarea = event.target;
    // textarea.style.height = 'auto'; // Reset the height to auto
    textarea.style.height = `${textarea.scrollHeight}px`; // Set the height to match the content

    const updatedOptions = [...pollData.options];
    updatedOptions[optionsIndex] = { option: value };
    if (value.length <= 50) {
      setPollData((prev) => ({ ...prev, options: updatedOptions }));
    }
  };

  const removeOption = (optionsIndex) => {
    if (pollData.options.length > 2) {
      const updatedOptions = [...pollData.options];
      updatedOptions.splice(optionsIndex, 1);
      setPollData((prev) => ({ ...prev, options: updatedOptions }));
    }
  };

  const appendOption = () => {
    if (pollData.options.length <= 10) {
      const updatedOptions = [...pollData.options];
      updatedOptions.push({ option: "" });
      setPollData((prev) => ({ ...prev, options: updatedOptions }));
    }
  };

  const handlePollQuestion = (event) => {

    const textarea = event.target;
    // textarea.style.height = 'auto'; // Reset the height to auto
    textarea.style.height = `${textarea.scrollHeight}px`; // Set the height to match the content
    
    const { value } = event.target;
    if (value.length <= 360)
      setPollData((prev) => ({
        ...prev,
        poll: value,
      }));
  }; 

  const handlePollDuration = (event, value) => {
    event.preventDefault();
    setPollData((prev) => ({ ...prev, duration: JSON.stringify(value) }));
  };

  const validateValues = (event) => { 
    event.preventDefault();
    let isError = [];

    isError.push(!Boolean(pollData.poll.length));

    const optionsError = [];
    pollData.options.forEach((option, index) => {
      isError.push(!Boolean(option.option.length));
      optionsError[index] = !Boolean(option.option.length);
    });
    setPollError((prev) => ({
      ...prev,
      options: optionsError,
      poll: !pollData.poll.length,
      duration: !Boolean(pollData.duration.length),
    }));
    isError.push(!Boolean(pollData.duration.length));
    const finalPollError = isError.some((err) => err);

    if (finalPollError) {
      window.scrollTo({
        top: 200,
        left: 0,
        behavior: "smooth",
      });
      setErrorAlert(true);
    } else {
      if (isLoggedIn) { 
        // if(userInfo.username) {
        //   setShowUserDetailsPopup(true);
        // } else {
          createPollApi();
          setPollAlert({ state: false, message: "" });
        // }
      } else {
        localStorage.setItem("draft_poll_data", JSON.stringify(pollData));
        setOpenSignUpForm(true);
      }
    }
  };
 
  const createPollApi = () => {
    PollServiceInstance.createPoll(pollData).then((response) => {
      if (response.success) {  
        setPollDataForImage({_id: response?.data?._id, ...pollData}); 
        setTimeout(() => {
          
          navigate(`/poll/${response.data.slug}`);  
          setErrorAlert(false); 
          localStorage.removeItem("draft_poll_data"); 
        }, 100);
      }
    });
  };

  const retrievePollDataAfterSignUp = () => {
    const retrievedPollData = localStorage.getItem("draft_poll_data");
    if (retrievedPollData) {
      setPollData(JSON.parse(retrievedPollData));
      setPollAlert({
        state: true,
        message: "You are now logged in. You can now submit the poll",
        variant: "info",
      });
    }
  };

  return (
    <div className="pollcard bg-lightgrey h-full w-full flex flex-col items-center">
      <div className="header items-center flex flex-col md:m-12 m-6 mb-2 ">
        <div className="heading text-primary-light md:text-4xl text-2xl font-bold">
          Create a Poll
        </div>
        <div className="mt-4 text-[16px] text-[#75859C] text-center">
        Unlock valuable insights by reaching thousands of people   
        </div>
      </div>
      <div className="content flex flex-col md:w-[70%] w-full items-center">
        {pollAlert.state && (
          <Alert message={pollAlert.message} severity={pollAlert.severity} />
        )}
        <div className="side-bar mx-4 w-full flex flex-col items-start bg-white rounded-lg drop-shadow-xl">
          <div className="question flex flex-col w-full md:p-8 p-3">
            <form onSubmit={validateValues}>
              <div className="w-full md:p-6 p-3">
                <div className="question-heading flex items-center justify-between">
                  <div className="text-primary-light text-base font-medium">
                    Your Question *
                  </div>
                  <Chip>{`${pollData.poll?.length} / 360`}</Chip>
                </div>
                <div className="question-input w-full">
                  <textarea
                    maxLength="360"
                    rows="3"
                    type="text"
                    name="poll"
                    style={{ overflowY: "hidden", resize: "none" }}
                    className="h-1 py-1 sm:pb-12 pb-8 text-base w-full word-break mt-4 border-b border-solid border-[#ced4da] focus:border-[#0d6efd] ease-in duration-200 outline-none"
                    placeholder="Add question here..."
                    value={pollData.poll}
                    onChange={handlePollQuestion}
                  />
                  {pollError?.poll && (
                    <div className="error-text text-primary-dark sm:mt-2 sm:text-sm text-xs">
                      This field is required.
                    </div>
                  )}
                </div>
              </div>
              <motion.div
                variants={container}
                initial="hidden"
                animate="show"
                className="w-full"
              >
                {pollData?.options?.map((option, index) => {
                  return (
                    <motion.div
                      variants={item}
                      key={index}
                      className="answer-input w-full mt-6 md:p-6 p-3 rounded-lg"
                    >
                      <div className="answer-header flex justify-between">
                        <div className="text-primary-light text-base font-medium">
                          {`Answer ${index + 1} *`}
                        </div>
                        <div className="flex items-center space-x-4">
                          {index > 1 && (
                            <div
                              className="cursor-pointer"
                              onClick={() => removeOption(index)}
                            >
                              <img
                                className="ml-4 w-[22px]"
                                src="/assets/images/trash.png"
                                alt="del"
                              />
                            </div>
                          )}
                          <Chip>{`${option?.option.length} / 50`}</Chip>
                        </div>
                      </div>
                      <textarea
                        type="text"
                        name={`options[${index}] `}
                        style={{ overflowY: "hidden", resize: "none" }}
                        className="h-6 py-1 sm:pb-12 pb-8 text-base w-full word-break mt-4 border-b border-solid border-[#ced4da] focus:border-[#0d6efd] ease-in duration-200 outline-none"
                        placeholder="Add answer here..."
                        maxLength="50"
                        value={option.option}
                        onChange={(event) =>
                          handleOptionChange(index, event)
                        }
                      />
                      {Array.isArray(pollError?.options) &&
                        pollError?.options[index] && (
                          <div className="error-text text-[#FF3333] sm:mt-2 sm:text-sm text-xs">
                            This field is required.
                          </div>
                        )}
                    </motion.div>
                  );
                })}
              </motion.div>
              <div className="mt-4 w-full flex justify-center">
                <Button
                  disabled={pollData.options.length >= 10}
                  height="3.5rem"
                  width="100%"
                  variant="outlined"
                  title="+ Add Option"
                  onClick={appendOption}
                />
              </div>

              <div className="pt-6 pl-2 foot sm:mt-6 w-full xl:px-6 overflow-x-hidden min-w-full">
                <div className="text-primary-light text-base font-medium pb-3">
                  Expires (In days)
                </div>
                <div className="flex justify-start items-center w-full sm:mt-6 p-2 space-x-4 overflow-x-auto">
                  {pollDurations?.map((duration) => (
                    <button
                      key={duration.value}
                      className={`${
                        pollData.duration === JSON.stringify(duration.value)
                          ? "shadow-md active:bg-darkgrey rounded-full bg-primary-dark p-2 md:px-6 px-2 text-white ease-in duration-300"
                          : "shadow-md active:bg-darkgrey rounded-full bg-white p-2 md:px-6 px-2 text-primary-dark hover:text-white hover:bg-primary-dark ease-in duration-300"
                      } w-full md:text-base text-sm hover:scale-[1.1]`}
                      type="button"
                      onClick={(event) => {
                        handlePollDuration(event, duration.value);
                      }}
                    >
                      {duration.label}
                    </button>
                  ))}
                </div>
                {pollError?.duration && (
                  <div className="text-[#FF3333]">* Required</div>
                )}
                {/* <div className="flex items-center sm:space-x-4 mt-6">
                  <span className="font-semibold text-lg mr-[1rem]">
                    <Chip>Note</Chip>
                  </span> 
                  <div className="text-[#75859C] text-base flex justify-center mt-0">
                  Kindly avoid posting anything sensitive or inappropriate!  
                  </div>
                </div> */}
              </div>
              <div className="action-btns w-full flex sm:flex-row flex-col sm:justify-end mt-12 items-center">
                {errorAlert && (
                  <div className="text-[#FF3333] text-xs md:text-sm">
                    It seems you missed a field. Please fill all fields to
                    continue.
                  </div>
                )}
                <button
                  type="submit" 
                  className="w-[20%] md:h-[3.0rem] rounded-full bg-primary-light p-2 sm:px-6 text-light text-white m-2 hover:bg-primary-dark ease-in duration-300 drop-shadow-lg active:bg-darkgrey mx-auto"
                >
                  Post
                </button>
              </div>
            </form>
          </div>
        </div> 
      </div>
      {openSignUpForm && (
        <SignupFormModal
          handleClose={() => {
            setOpenSignUpForm((prev) => !prev);
          }}
          onSuccess={() => {
            window.location.reload();
            return retrievePollDataAfterSignUp();
          }
          }
        />
      )}
        
      {pollDataForImage?.poll?.length && ( 
        <DisplayCard pollData={pollDataForImage}/> 
      )}  

      {false && showUserDetailsPopup && (
        <UniversalPopup
          title="Profile Details"
          handleClose={() => { 
            fetchMe();
            setShowUserDetailsPopup(false);
          }}
        >
          <div className="p-10">
            <div className="w-full my-4 p-4 border-[0.02rem] border-solid border-[#DC3545] bg-[#FFDDDD] flex items-center">
              <i className="las la-exclamation-circle la-lg text-[#DC3545] mr-1" />
              <span className="text-[#DC3545] sm:text-sm text-xs">
                You have not updated your username.
              </span>
            </div>
            <UserDetails popupReason={'create'}/>
          </div>
        </UniversalPopup>
      )}
    </div>
  );
};

export default CreatePollPage;
