import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import AuthServiceInstance from "../../../services/authService";
import SignupFormModal from "../modals/SignupFormModal";
import { useContext } from "react";
import { UserContext } from "../../../App";

const s3Url = process.env.REACT_APP_S3_CDN_URL;

const NavBar = () => {
  const userLogin = useContext(UserContext);
  const [showSignIn, setShowSignIn] = useState(true);
  const [profilePicture, setProfilePicture] = useState(null);
  const [userId, setuserId] = useState(null);
  const navigate = useNavigate();

  const fetchMe = () => {
    AuthServiceInstance.me()
      .then((response) => {
        if (response.success) {
          setProfilePicture(response.data?.picture);
          setuserId(response.data._id);
        }
      })
      .catch();
  };

  useEffect(() => {
    if (userLogin.state.isLoggedIn) {
      fetchMe();
      setShowSignIn(false);
    } else {
      setShowSignIn(true);
    }
  }, [userLogin.state.isLoggedIn]);

  useEffect(() => {
    if (userLogin.state.profileStatus === "updated") {
      fetchMe();
    }
  }, [userLogin.state]);

  const location = useLocation();
  var currentLocation = location.pathname.replaceAll("/", "");

  const handleSignup = () => {
    userLogin.showSignupPopup(true);
  };

  const handleProfile = () => {
    if (userId !== null) navigate(`/profile/${userId}`);
  };

  return (
    <div className="navbar flex sm:flex-row flex-row justify-between items-center bg-[#181A29] text-white min-h-[4.5rem] w-full lg:px-32 px-2 md:px-12">
      <img
        src="/assets/logo/logo2.svg"
        alt="Opinione"
        onClick={() => navigate("/")}
        className="cursor-pointer"
      />

      <div className="flex items-center md:space-x-4 space-x-1 mr-3">
        {currentLocation !== "create-poll" && (
          <button
            onClick={() => navigate("/create-poll")}
            className="create-poll text-[1rem] md:text-[1rem] flex items-center text-primary-light bg-transparent rounded-full p-[0.40rem] md:p-[0.7rem] my-2 px-8 hover:bg-[#f6f7fc] ease-in duration-200 border-2 border-white mr-1 sm:mr-2"
          > 
            <span className="ml-1 md:ml-3 lg:ml-5 mr-1 hidden sm:block md:block">Create </span>
            {/* <i className="las la-plus" /> */} 
            <p> </p>
            <span className="md:mr-3 lg:mr-5"> Poll +</span>
          </button>
        )}
        {showSignIn ? (
          <button
            onClick={handleSignup}
            className="signup-btn md:text-base text-xs flex items-center bg-primary-light text-white rounded-full p-[0.7rem] my-2 px-8 hover:bg-primary-dark ease-in duration-200 border-2 border-white"
          >
            <span className="mr-2">SIGN IN</span>
            <img src="/assets/images/right-arrow.png" alt="->" />
          </button>
        ) : (
          <button
            onClick={handleProfile}
            className="myprofile-btn overflow-hidden md:text-base text-sm relative flex items-center bg-primary-light text-white rounded-full py-[0.7rem] my-2 pr-6 pl-2 hover:bg-primary-dark ease-in duration-200 border-2 border-white min-w-[70px]"
          >
            <img
              src={
                profilePicture !== null
                  ? `${s3Url}/${profilePicture}`
                  : "/assets/images/dummyUser.png"
              }
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;  
                currentTarget.src="/assets/images/dummyUser.png";
              }} 
              alt="user"
              className="md:w-[3rem] md:h-[3rem] w-[2.5rem] h-[2.5rem] left-0 absolute m-0 rounded-full"
            />
            <span className="sm:ml-12 ml-10 mr-2 md:text-base sm:text-xs text-[0.6rem] hidden sm:block md:block">
              My Profile
            </span>
            <img
              src="/assets/images/right-arrow.png"
              alt="->"
              className="mt-[0.07rem] ml-auto"
            />
          </button>
        )}
      </div>
      {userLogin.state.showSignUp && (
        <SignupFormModal
          handleClose={() => {
            userLogin.showSignupPopup(false);
          }}
          onSuccess={() => {
            window.location.reload();
            fetchMe();
            setShowSignIn(false);
          }}
        />
      )}
    </div>
  );
};

export default NavBar;
