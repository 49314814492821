import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet"; 
import Loader from "../components/Loader/loader";
import { Discuss, RotatingSquare } from "react-loader-spinner";
import { useNavigate, useParams } from "react-router";
import { UserContext } from "../../App";
import AuthServiceInstance from "../../services/authService";
import ChartServiceInstance from "../../services/chartService";
import DiscussionServiceInstance from "../../services/discussionService";
import PollServiceInstance from "../../services/pollService";
import { camelCaseToTitleCase } from "../../utils/formatters";
import Button from "../components/buttons/Button";
import PollCard from "../components/cards/pollCard";
import DoughnutChart from "../components/charts/doughnutChart";
import CommentCard from "../components/comments/commentCard";
import { MenuItem, Select, OutlinedInput, InputLabel, FormControl } from "@mui/material";
import PollHelperInstance from "../../utils/pollHelper";
import { number } from "yup";
const server_url = process.env.REACT_APP_CDN_URL;

const PollDetailsPage = () => {
  const {
    state: { isLoggedIn },
    showSignupPopup,
  } = useContext(UserContext);
  const param = useParams();
  const pollSlug = param?.slug;
  const navigate = useNavigate();

  const [pollData, setPollData] = useState(null);
  const [isShowResults, setIsShowResults] = useState(false);
  const pollLink = `${server_url}/poll/${pollSlug}`;
  const pollImage = `${server_url}/assets/images/poll_test.jpg`;
  const [discussionData, setDiscussionData] = useState([]);
  const [refetchComments, setRefetchComments] = useState(false);
  const [isPollLoading, setIsPollLoading] = useState(false);
  const [commentText, setCommentText] = useState("");
  const [isDiscussionsLoading, setIsDiscussionsLoading] = useState(false);
  const [userInfo, setUserInfo] = useState(null);

  const [chartData, setChartData] = useState({});

  const [pollId, setPollId] = useState(null);
 
  const fetchPollData = () => {
    setIsPollLoading(true);
    if (localStorage.getItem("token")?.length) fetchMe();
    PollServiceInstance.getPollBySlug(pollSlug)
      .then((response) => {
        setPollData(response[0]);
        setPollId(response[0]._id);  
      })
      .catch()
      .finally(() => setIsPollLoading(false));
  }

  useEffect(() => { 
    // window.scrollTo({ top: 560, behavior: 'smooth' });
    
    if (localStorage.getItem("token")?.length) fetchMe();
    setIsPollLoading(true); 
    fetchPollData();
 
  }, []);

  const userHasVoted = (flag) => { 
    if (flag) {               
      fetchPollData();
      isShowResultsRefresh(true);  
      refreshData();  
      return true;
    }
  };

  const isShowResultsRefresh = (flag = false) => {
    const isShowResultLocal = (pollData?.voted?.option !== "" && pollData?.voted?.option !== null && pollData?.voted?.option !== undefined && isLoggedIn) || flag;
     
    setIsShowResults(isShowResultLocal);   
  }; 

  useEffect(() => {   
    isShowResultsRefresh(pollData?.voted?.option !== "" && pollData?.voted?.option !== null && pollData?.voted?.option !== undefined);
  }, [pollData, isLoggedIn]);

  useEffect(() => { 
    refreshData(); 
  }, [pollId,isShowResults]);


  const refreshData = () => {
    handleAnalyticsChange({name: 'gender', value: ''}); 
    handleAnalyticsChange({name: 'age', value: ''}); 
  }
  const fetchMe = async () => {
    const user = await AuthServiceInstance.me();
    if (user) {
      setUserInfo(user.data);
    }
  };

  const fetchPollAgeChartData = async (optionId = "") => {
    const ageData = await ChartServiceInstance.ageChart(pollId, optionId);

    if (ageData) {
      const actualData = ageData.numberData;
       
      let numberOfNonZero = 0;
      actualData.map((data) => {
        if(data.value > 0)
        numberOfNonZero++;
      }); 

      setChartData((prev) => ({
        ...prev,
        age: {
          labels: actualData.map((data) =>
            data.title
          ),
          datasets: [
            {
              label: "",
              data: ageData.percentData.map((data, index) => 
              isShowResults ? data.value : 100),   
              backgroundColor: [
                '#17CEA2',
                '#D15AD3',
                "#fa5c3c", 
                '#FFC700',
              ], 
            },
          ],
          percentData: ageData.percentData,
        },
      }));
    } else {
      setChartData((prev) => ({
        ...prev,
        age: {
          labels: [],
          datasets: [],
        },
      }));
    }
  };

  const fetchPollGenderChartData = async (optionId = "") => { 
    const genderData = await ChartServiceInstance.genderChart(pollId, optionId);
    if (genderData) {             
      const actualData = genderData.numberData;
      
      let numberOfNonZero = 0;
      actualData.map((data) => {
        if(data.value > 0)
        numberOfNonZero++;
      }); 

      setChartData((prev) => ({
        ...prev,
        gender: {
          labels: actualData.map((data) =>
            data.title
          ),
          datasets: [
            {
              label: "",
              data: genderData.percentData.map((data, index) => 
              isShowResults ? data.value : 100),  
              backgroundColor: [
                "#17CEA2",
                "#D15AD3",
                "#fa5c3c",   
              ],
            },
          ],
          percentData: genderData.percentData,
        },
      }));
    } else {
      setChartData((prev) => ({
        ...prev,
        gender: {
          labels: [],
          datasets: [],
        },
      }));
    }
  };

  const fetchDiscussionsData = () => {
    setIsDiscussionsLoading(true);
    DiscussionServiceInstance.getDiscussions(pollId)
      .then((response) => {
        setDiscussionData(response);
      })
      .catch()
      .finally(() => {
        setIsDiscussionsLoading(false);
      });
  };

  const refreshDiscussion = () => { 
    fetchDiscussionsData();
  }
 

  useEffect(() => {
    if (pollId) {
      fetchDiscussionsData();
    }
  }, [refetchComments, pollId]);

  const addComment = () => {
    if (commentText.length)
      if (isLoggedIn) {
        if (pollId)
          DiscussionServiceInstance.addDiscussion(pollId, commentText)
            .then((response) => {
              if (response.success) {
                setCommentText("");
                setRefetchComments((prev) => !prev);
              }
            })
            .catch();
      } else {
        showSignupPopup(true);
      }
  };

  const handleComment = ({ target: { value } }) => {
    if (value.length <= 1024) setCommentText(value);
  };

  const [optionAnalyticsObject, setOptionAnalyticsObject] = useState({
    gender: "",
    age: "",
  });

  const handleAnalyticsChange = (eventTarget) => {
    const { name, value } = eventTarget; 
    setOptionAnalyticsObject((prev) => ({ ...prev, [name]: value })); 
   
    if (pollId) { 
      switch (name) {
        case "age": 
          fetchPollAgeChartData(value);
          break;
        case "gender":
          fetchPollGenderChartData(value);
          break;
        default:
          break;
      }
    }
  };

 
  const renderPercentData = (dataInObjectFormat) => { 
    const colors = ["text-[#17CEA2]", "text-[#D15AD3]", "text-[#fa5c3c]", "text-[#FFC700]"]; 
    if(dataInObjectFormat) {
      const dataInArrayFormat = dataInObjectFormat.map(
        (value) => ({ title: value.title, value: isShowResults ?  value.value : 100 })
      );
      return dataInArrayFormat.map((data, index) => (
        <div
          className={`flex items-center ${colors[index]} drop-shadow-sm justify-left`}
        >
          [<span >{data.value}%</span>] ({data.title}) 
        </div>
      ));
    }
    
  }; 

  return (
    <> 
      {!isPollLoading ? (
        <>
          {pollData?.poll?.length ? (
            <div className="main bg-lightgrey w-full flex flex-col items-center px-2 md:px-8 xl:px-32 overflow-none">
              <div className="header items-center flex flex-col m-12 w-full">
                <div className="heading text-primary-light text-4xl font-bold">
                  Analytics
                </div>
                <div className="subheading my-4 mb-12 text-[#75859C] w-full text-center">
                  Discover the power of your opinions! Explore our poll
                  results and gain valuable insights to drive your success.
                </div>
                <div className="content flex lg:flex-row flex-col w-full">
                  {/* left content */}

                  <div className="left-content flex flex-col w-full lg:mx-2 pb-5">
                    <div className="drop-shadow-lg">
                      {!(pollData === undefined) && (
                        <PollCard
                          data={pollData}
                          from={'PollDetail'}
                          userHasVoted={userHasVoted}  
                          userInfo={userInfo}
                        />
                      )}
                    </div>
                    <div className="my-6 flex flex-col items-start bg-white rounded-2xl drop-shadow-lg p-4 w-full md:min-w-[20rem] min-w-[10rem]">
                      <div className="flex items-start justify-between w-full">
                        <div className="flex flex-col items-start min-w-[50%]">
                          <div className="text-xl font-medium">Gender</div>
                          <div className="divider border-b-[0.4rem] border-solid border-primary-light w-[2.5rem] my-2" />
                        </div>
                        {pollData !== undefined && isShowResults && (
                          <>
                          <div className="w-full max-w-[50%]">
                          <FormControl sx={{ width: '60%', float: "right" }} size="small">
                          <InputLabel id="demo-multiple-name-label">Option</InputLabel>
                          <Select 
                          labelId="demo-multiple-name-label"
                          id="demo-multiple-name"
                          className="w-[100%]" 
                            value={optionAnalyticsObject.gender || ""}
                            placeholder="choose an option" 
                            name="gender"  
                            input={<OutlinedInput label="Option" /> } 
                            onChange={(event) =>
                              handleAnalyticsChange(event.target)
                            }
                            onLoad={(event) =>
                              handleAnalyticsChange(event.target)
                            } 
                          >
                            <MenuItem className="text-primary-light" value="">
                              -select option-
                            </MenuItem>
                            {pollData.options.map((option) => (
                              <MenuItem
                                className="text-primary-light"
                                value={option._id}
                                key={option._id}
                              >
                                {option.option}
                              </MenuItem>
                            ))}
                          </Select>
                          </FormControl>
                          </div>
                          </>
                        )}
                      </div>
                      <div className="graph w-full flex justify-center items-center flex-wrap"> 
                        {chartData?.gender && (
                          <>
                          <div className="flex flex-wrap relative gender-chart items-center justify-center">
                          <div className={isShowResults ? "" : "opacity-30"}>
                          <div className="w-[300px]">
                          <DoughnutChart chartData={chartData?.gender} /> 
                          </div>
                          </div> 
                          { !isShowResults && (
                          <div className="absolute pt-[55px] items-center justify-center flex flex-col text-center"> 
                          <Button
                            title="Show"
                            variant="filled"
                            onClick={() => window.scroll({top: 0, behavior: 'smooth' })}
                          /> 
                          </div>
                          )}  
                          </div>  
                          <div className={isShowResults ? " " : "opacity-30 " + "flex flex-col items-center space-y-3 mobile-full"}>
                            {renderPercentData(chartData?.gender?.percentData)}
                          </div>
                          </> 
                        )}
                      </div>
                      { !isShowResults && ( <em className="text-[#181A29] text-[12px] mt-4 d-block text-center w-full">This is just a representation Please vote to see the actual results</em> ) }
                    </div>

                    <div className="flex flex-col items-start bg-white rounded-2xl drop-shadow-lg p-4 w-full md:min-w-[20rem] min-w-[10rem]">
                      <div className="flex items-start justify-between w-full">
                        <div className="flex flex-col items-start min-w-[50%]">
                          <div className="text-xl font-medium">City | Profession | Earning </div>
                          <div className="divider border-b-[0.4rem] border-solid border-primary-light w-[2.5rem] my-2" />
                          Upcoming in the next update...
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* right content */}

                  <div className="right-content flex flex-col space-y-6 lg:mx-2 w-full">
                    <div className="flex flex-col items-start bg-white rounded-2xl drop-shadow-lg p-4 w-full md:min-w-[20rem] min-w-[10rem]">
                      <div className="flex items-start justify-between w-full">
                        <div className="flex flex-col items-start min-w-[50%]">
                          <div className="text-xl font-medium">Age</div>
                          <div className="divider border-b-[0.4rem] border-solid border-primary-light w-[2.5rem] my-2" />
                        </div>
                        {pollData !== undefined && isShowResults && (
                          <>
                          <div className="w-full max-w-[50%]">
                          <FormControl sx={{ width: '60%', float: "right" }} size="small">
                          <InputLabel id="demo-multiple-name-label">Option</InputLabel>
                          <Select 
                          labelId="demo-multiple-name-label"
                          id="demo-multiple-name"
                          className="w-[100%]" 
                            value={optionAnalyticsObject.age || ""}
                            placeholder="choose an option" 
                            name="age"  
                            input={<OutlinedInput label="Option" /> } 
                            onChange={(event) =>
                              handleAnalyticsChange(event.target)
                            }
                            onLoad={(event) =>
                              handleAnalyticsChange(event.target)
                            } 
                          >
                            <MenuItem className="text-primary-light" value="">
                              -select option-
                            </MenuItem>
                            {pollData.options.map((option) => (
                              <MenuItem
                                className="text-primary-light"
                                value={option._id}
                                key={option._id}
                              >
                                {option.option}
                              </MenuItem>
                            ))}
                          </Select>
                          </FormControl>
                          </div>
                          </>
                        )}
                      </div> 
                      <div className={ ((!isShowResults) ? "graph w-full flex justify-center items-center flex-wrap" : "graph w-full flex justify-center items-center flex-wrap")}>
                      
                      {chartData?.age && (
                        <>
                        <div className="flex flex-wrap relative age-chart items-center justify-center">
                          <div className={isShowResults ? "" : "opacity-30"}>
                          <div className="w-[300px]">
                            <DoughnutChart chartData={chartData?.age} /> 
                          </div>
                          </div> 
                          { !isShowResults && (
                          <div className="absolute pt-[55px] items-center justify-center flex flex-col text-center"> 
                          <Button
                            title="Show"
                            variant="filled"
                            onClick={() => window.scroll({top: 0, left: 0, behavior: 'smooth' })}
                          />  
                          </div>
                          )}
                          </div> 
                        <div className={isShowResults ? " " : "opacity-30 " + "flex flex-col items-center space-y-3 mobile-full"}>
                          {renderPercentData(chartData?.age?.percentData)}
                        </div> 
                        </>
                      )}
                      </div>
                      { !isShowResults && (
                      <em className="text-[#181A29] text-[12px] mt-4 d-block text-center w-full">This is just a representation Please vote to see the actual results</em> ) }
                    </div> 
 
                    <div className="discussion flex flex-col items-start bg-white rounded-2xl drop-shadow-lg p-4 my-4 w-full ">
                      <div className="text-xl font-medium">Discussion</div> 
                      <div className="divider border-b-[0.4rem] border-solid border-primary-light w-[2.5rem] my-2 mb-6 " />
                      {!isDiscussionsLoading ? (
                        <div className="comment-section w-full space-y-4">
                          {discussionData?.length > 0 ? (
                            <>
                              {discussionData?.map((discussion, index) => (
                                <div
                                key={index}
                                className="w-full flex items-center item_com word-break"
                                >
                                  <CommentCard 
                                    key={index}
                                    data={discussion}
                                    pollId={pollId}
                                    refreshDiscussion={refreshDiscussion}
                                    isLoggedIn={isLoggedIn}
                                    showSignupPopup={showSignupPopup}
                                  />
                                </div>
                              ))}
                            </>
                          ) : (
                            <div className="">Be the first to share your unique opinion.</div>
                          )}
                        </div>
                      ) : (
                        <>
                        <div>
                        <Loader />

                        </div>
                        </>
                        // <Discuss
                        //   key={0}
                        //   visible={true}
                        //   height="80"
                        //   width="80"
                        //   ariaLabel="comment-loading"
                        //   wrapperStyle={{}}
                        //   wrapperclassName="comment-wrapper"
                        //   color="#fff"
                        //   backgroundColor="#EE585D"
                        // />
                        
                      )}
                      <div className="w-full">
                        <div className="add-comment mt-6 w-full">
                          <textarea
                            value={commentText}
                            onChange={handleComment}
                            className="w-full border-[0.08rem] border-solid border-[#DADADA] outline-none rounded p-4 h-auto min-h-[8rem] bg-[#f8f8f8]"
                            placeholder="write something, worth reading..."
                          />
                        </div>
                        <button
                          onClick={() => addComment()}
                          className="rounded-full bg-primary-light p-2 px-6 text-light text-white my-4 hover:bg-primary-dark ease-in duration-300 float-right"
                        >
                          Post
                        </button>
                      </div>
                    </div> 
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="h-[100vh] w-full flex flex-col space-y-4 items-center justify-center bg-lightgrey">
              <p className="text-6xl font-bold text-primary-light drop-shadow-lg">
                Oop!
              </p>
              <span className="text-primary-dark text-2xl">
                Poll Not Found !
              </span>
              <Button
                title="Go back to Home"
                variant="filled"
                onClick={() => navigate("/")}
              />
            </div>
          )}
        </>
      ) : (
        <div className="w-full min-h-screen flex justify-center">
          Loading...
        </div>
      )}
    </>
  );
};

export default PollDetailsPage;
